import type { ModelBaseDef } from "@/utils/defs";
import { expandThumbs } from "paparazzi/components/stores/thumbnail_conversion";
import type { Ref } from "vue";
import { uapi } from "./api";

import {
  colorOptions,
  metalOptions,
  type Mirror,
  type Product,
} from "@/stores/defs/shop_defs";
import { createLogger } from "@paparazzi/utils/debug";
import { timer } from "@paparazzi/utils/promise";
import api from "@virgodev/bazaar/functions/api";
import localforage from "localforage";
import sortBy from "sort-by";

const log = createLogger("patcher");
const detailLog = createLogger("patcher-detail");

const timeoutDuration = import.meta.env.NODE_ENV === "test" ? 10 : 2000;

interface ExtraDef {
  stock?: number;
  timestamp?: Date;
}

export class Patcher<T extends ModelBaseDef> {
  url: string;
  array: Ref<T[]>;
  percent: Ref<number>;
  cache: LocalForage;
  pending: ModelBaseDef[] = [];
  patching: number = 0;
  cleanup: number = 0;
  isPatching: boolean = false;
  sortFields?: string[];

  // Warning: setting the page size to 50 will cause NGINX
  // to time out servers because of the size of the get request
  // for products.
  pageSize = 25;
  promise: Promise<void> | null = null;
  _resolve: Function | null = null;
  _cacheResolve: Function | null = null;
  _cachePromise: Promise<void>;
  attr: "id" | "slug" = "id";
  mirror?: Mirror;

  constructor(
    url: string,
    array: Ref<T[]>,
    percent: Ref<number>,
    mirror?: Mirror,
    sortFields?: string[],
  ) {
    this.url = url;
    this.array = array;
    this.percent = percent;
    this.cache = localforage.createInstance({
      name: "paparazzi",
      storeName: `${url}_cache`,
    });
    this._cachePromise = new Promise((resolve) => {
      this._cacheResolve = resolve;
    });
    this.mirror = mirror;
    this.sortFields = sortFields;
    this.setup();
  }

  setup() {}

  getKey(model: ModelBaseDef): string {
    return (model[this.attr] || "n/a").toString();
  }

  async loadCache() {
    log("loading from cache", this.url);
    let expired = 0;
    const uncached: ModelBaseDef[] = [];
    await this.cache.iterate((value: any, key: string) => {
      if (value) {
        try {
          const cutoff = Date.now() - 1000 * 60 * 60 * 48;
          if (value.cached_time && value.cached_time > cutoff) {
            uncached.push(value);
          } else {
            this.cache.removeItem(`${key}`);
            expired += 1;
          }
        } catch (ex) {
          console.warn("error in iteration", ex);
        }
      } else {
        console.warn("remove from cache", key);
        this.cache.removeItem(`${key}`);
      }
    });

    for (const value of expandThumbs(uncached)) {
      // const index = this.array.value.findIndex((i) => i.id === value.id);
      // if (index === -1) {
      this.array.value.push(this.migrateItem(value));
      // }
    }

    if (expired) {
      log("expired", this.url, expired, "objects");
    }
    log("loaded from cache", this.url, this.array.value.length);
    if (this._cacheResolve) {
      this._cacheResolve();
      this._cacheResolve = null;
    }
  }

  async patch(id: string | number, extra: ExtraDef = {}) {
    if (this._cacheResolve) {
      await this._cachePromise;
    }

    let retval = null;
    const alreadyPatching = this.pending.find((p) => p.id === id);
    const existing = this.array.value.find(
      (p: ModelBaseDef) => p[this.attr] == id,
    );
    const skip = id === "_updated" || this.shouldSkip(extra, existing);
    if (!alreadyPatching && !skip) {
      if (existing) {
        // log('timestamp', id, !!existing.timestamp, existing.timestamp >= extra.timestamp);
      } else {
        log("no existing", this.url, id);
      }
      // console.warn("adding to pending", this.url, id, skip);
      this.promise = new Promise((resolve) => {
        this._resolve = resolve;
      });
      retval = new Promise((r) => {
        this.pending.push({ id, extra, resolve: r });
      });
    }
    // if (retval && !this.isPatching) {
    this.finishPatch();
    // }
    return retval;
  }

  shouldSkip(extra: ExtraDef, existing?: ModelBaseDef): boolean {
    if (!existing) {
      return false;
    }
    if (extra.timestamp) {
      return !!existing.timestamp && existing.timestamp >= extra.timestamp;
    }
    return true;
  }

  async finishPatch(timeout = timeoutDuration) {
    clearTimeout(this.patching);
    this.patching = window.setTimeout(
      async () => {
        log("finishing patch", this.url, this.pending.length);
        this.isPatching = true;

        // precache products
        let cachedList = [];

        // TODO:
        // try {
        //   await this.checkServer();
        // } catch (ex) {
        //   console.error(ex);
        //   this.server = {
        //     yp: true,
        //     error: "failed to connect",
        //     timestamp: Date.now() + 5000,
        //   };
        // }

        log("pending", this.url, this.pending.length);
        let slice = this.pending.splice(0, this.pageSize);
        let count = 0;
        const times = [];
        const maxAttempts = 3;
        const speed = 1000;
        while (slice.length > 0) {
          let response = null;
          let attempts = maxAttempts;

          while (response === null && attempts > 0) {
            try {
              const startTime = Date.now();
              log(
                `${this.url} patching`,
                slice.length,
                "items;",
                this.pending.length,
                "remaining in queue",
              );

              response = await uapi({
                url: `${this.url}/patch/`,
                params: {
                  ids: slice.map((p) => p.id),
                  msgpack: true,
                  dt: Date.now(),
                },
                // POST will do a compare
                method: "GET",
              });

              times.push(Date.now() - startTime);
              if (
                response &&
                attempts === maxAttempts &&
                response.body.length < slice.length
              ) {
                log(
                  this.url,
                  "failed to get all requested items, trying again",
                  response.body.length,
                  "vs",
                  slice.length,
                );
                await timer(5 * speed);
                response = null;
              }
            } catch (ex) {
              log(`failed to fetch ${this.url}`);
              console.log("fail", ex);
              await new Promise((r) =>
                setTimeout(r, (5 - attempts) * speed + Math.random() * speed),
              );
            }
            attempts -= 1;
          }
          if (response === null) {
            console.warn(
              "We failed to fetch products at this time, please try again in a moment",
            );
            return;
          }

          const responseItems = [];
          if (response.ok) {
            for (const item of response.body) {
              let item_id = `${item[this.attr]}`;
              const instance = this.migrateItem(item);
              const object = slice.find((p) => p.id === item_id);
              if (object) {
                if (object.extra) {
                  for (const key in object.extra) {
                    instance[key as keyof T] = object.extra[key];
                  }
                }
                // if (object.old && (!instance.images || instance.images.length === 0)) {
                //   instance.images = object.old.images;
                // }
                if (object.resolve) {
                  object.resolve(instance);
                }
                object.resolved = true;
              }
              responseItems.push(instance);
              count += 1;
              // this.current.push(item[attr]);
            }
          }
          if (responseItems.length < slice.length) {
            log(
              `${this.url} failed to get items: ${responseItems.length} vs ${slice.length}`,
            );
            for (const item of slice) {
              if (!responseItems.find((i) => i[this.attr] === item.id)) {
                log(` - ${item.id} (${this.url})`);
                const response = await api({
                  url: `products/${item.id}/`,
                });
                if (response.ok) {
                  responseItems.push(response.body);
                }
              }
            }
          }
          for (const part of slice.filter((p) => !p.resolved)) {
            if (part.resolve) {
              part.resolve(null);
            }
          }
          for (let item of expandThumbs(responseItems)) {
            item.cached_time = new Date();
            this.cache.setItem(`${item.id}`, item);
            const index = this.array.value.findIndex((i) => {
              return i[this.attr] === item[this.attr];
            });
            if (index === -1) {
              detailLog(this.url, "added", item);
              this.array.value.push(item);
            } else {
              detailLog(this.url, "updated", item);
              this.array.value[index] = item;
            }
          }

          slice = this.pending.splice(0, this.pageSize);
        }

        // allow other patches to start now that all pending are finished
        this.isPatching = false;

        const avg = times.reduce((a, b) => a + b, 0) / times.length;
        log(`${this.url} patch average time: ${avg}ms`);

        const total = this.array.value.length;
        log(`${this.url} patch complete [${count}] (total: ${total})`);
        if (this._resolve) {
          this._resolve();
        }

        this.purgeFromMirror();
        this.clearOld();

        if (this.sortFields) {
          this.array.value.sort(sortBy(...this.sortFields));
        }

        this.percent.value = 1;
      },
      this.pending.length >= this.pageSize ? 0 : timeout,
    );
  }

  migrateItem(a: T): T {
    return a;
  }

  purgeFromMirror() {
    if (this.mirror) {
      const remove: (string | number)[] = [];
      for (const item of this.array.value) {
        const key = this.getKey(item);
        if (!this.mirror.data[key]) {
          remove.push(item.id);
        }
      }
      for (const itemId of remove) {
        const index = this.array.value.findIndex((p) => p.id === itemId);
        if (index !== -1) {
          const key = this.getKey(this.array.value[index]);
          detailLog(
            "removed item",
            this.url,
            itemId,
            key,
            index,
            this.array.value.length,
          );
          this.cache.removeItem(`${itemId}`);
          this.array.value.splice(index, 1);
        }
      }
    }
  }

  clearOld() {
    clearTimeout(this.cleanup);
    this.cleanup = window.setTimeout(
      () => {
        let count = 0;
        let index = 0;
        for (const p of this.array.value) {
          if (!p) {
            this.array.value.splice(index, 1);
            count += 1;
          } else if (
            // this.current[url].indexOf(p[attr]) === -1 ||
            (p.stock || 0) < -1
          ) {
            const index = this.array.value.findIndex((i) => {
              return i.id === p.id;
            });
            if (index > -1) {
              this.array.value.splice(index, 1);
              this.cache.removeItem(`${p.id}`);
            }
            console.warn("remove oos from cache", this.url, p[this.attr]);
            // TODO: cache.removeItem(`${url}/${p[attr]}/`);
            count += 1;
          }
          index += 1;
        }
        if (count > 0) {
          log(`cleared ${count} stale ${this.url}`);
        }
      },
      1000 * 60 * 5,
    );
  }
}

export class ProductPatcher<T extends Product> extends Patcher<T> {
  colors = [
    ...metalOptions.map((o) => o.label),
    ...colorOptions.map((o) => o.label),
    ...["Rose Gold", "Gunmetal"],
  ];

  setup() {
    this.attr = "slug";
  }

  migrateItem(a: T) {
    // remove the color string from the title,
    // HOPEFULLY this will only be needed while in beta
    const dash = a.name.lastIndexOf(" - ");
    if (dash > -1) {
      const color = a.name.slice(dash + 3); //.trim();
      if (this.colors.includes(color)) {
        a.name = a.name.slice(0, dash).trim();
      }
    }

    // add deployed date for sorting
    // the goal is to make release dates change the sorting after release
    a.deployed = new Date(a.date_added);
    if (a.release_date) {
      const deployed = new Date(a.release_date);
      const offset = new Date(deployed.getTime() - 1000 * 60 * 5);
      if (offset < new Date()) {
        a.deployed = new Date(a.release_date);
      }
    }
    return a;
  }
}
