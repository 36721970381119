import { type AuthorizeCreditCard } from "@paparazzi/types/paparazzi.authorizecim.views";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { createStore } from "@virgodev/vue-models/utils/create_store";
import { defineStore } from "pinia";
import { computed, watch } from "vue";
import { useFirebaseStore } from "./firebase";
import { useTimesyncStore } from "./timesync";
import { useUserStore } from "./user";

export interface AuthorizeCreditCardWithId extends AuthorizeCreditCard {
  id: number;
  cvc?: string;
}

export const useDefaultCardStore = createStore<AuthorizeCreditCardWithId>(
  "authorize",
  {
    syncTimeout: 1000 * 60 * 60 * 2,
    namespace: "paparazzi",
  },
);
export const useCardsStore = defineStore("cards", () => {
  const cards = useDefaultCardStore();
  const firebase = useFirebaseStore();
  const user = useUserStore();
  const storage = useLocalStorageStore();
  const timesync = useTimesyncStore();

  const lastUpdateKey = "creditCard:lastUpdate";
  const lastUpdate = computed({
    get: () => storage.get(lastUpdateKey, ""),
    set: (v) => storage.put(lastUpdateKey, v),
  });

  watch(
    () => firebase.userData[lastUpdateKey],
    async () => {
      console.log(
        "firebase updated",
        lastUpdateKey,
        firebase.userData[lastUpdateKey],
      );
      const now = new Date(lastUpdate.value || -1).toISOString();
      if (await firebase.shouldUpdate(lastUpdateKey, now)) {
        await cards.sync();
      }
    },
  );

  const list = computed(() => {
    return cards.list;
  });

  async function sync() {
    if (user.isAuthenticated) {
      await cards.sync();
    }
  }

  async function save(record: any) {
    const retval = await cards.save(record);
    const now = new Date(timesync.now).toISOString();
    lastUpdate.value = now;
    await firebase.update(lastUpdateKey, now, now);
    return retval;
  }

  async function remove(id: any) {
    const retval = await cards.remove(id);
    const now = new Date(timesync.now).toISOString();
    lastUpdate.value = now;
    await firebase.update(lastUpdateKey, now, now);
    return retval;
  }

  async function maybeSync() {
    await cards.maybeSync();
  }

  async function setup() {
    await cards.sync();
  }

  return {
    list,
    sync,
    save,
    remove,
    maybeSync,
    setup,
  };
});
